<template>
  <button v-on:click="requestPermission">通知を有効化</button>
  <pre>/rb_server/rb_server.cgi</pre>
</template>

<script>
import { getMessaging, getToken } from "firebase/messaging";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import axios from "axios";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export default {
  name: 'App',
  methods: {
    requestPermission: () => {
      Notification.requestPermission().then((permission) => {
        alert(permission);
        getToken(messaging, {vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY}).then((currentToken) => {

          var params = new URLSearchParams();
          params.append('token', currentToken);
          axios.post('/rb_server/rb_server.cgi', params)
          .then(res=>{
            console.log(res)
            alert(currentToken);
          })
          .catch(err=>{
            console.log(err)
            alert("post err");
          })

        });
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
